import { createTheme } from '@material-ui/core/styles';

export const muiTheme = createTheme({
  palette: {
    primary: {
      main: '#F9B233',
      light: '#fdeccc',
    },
  },
});
