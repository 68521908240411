import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { ReviewSourceLogosQuery } from '../../../../generated/graphql-types';
import { GatsbyImage } from 'gatsby-plugin-image';

export const ReviewSourceLogo = () => {
  const reviewSourceLogos = useStaticQuery<ReviewSourceLogosQuery>(graphql`
    query ReviewSourceLogos {
      google: file(relativePath: { eq: "google.png" }) {
        childImageSharp {
          gatsbyImageData(width: 40, placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
    }
  `);

  return (
    <GatsbyImage
      image={reviewSourceLogos.google.childImageSharp.gatsbyImageData}
      alt="Google"
    />
  );
};
