import React from 'react';
import Container from '../../core/layout/Layout/Container';
import Heading from '../../core/typography/Heading';
import { graphql, useStaticQuery } from 'gatsby';
import { OrangeBannerQuery } from '../../../../generated/graphql-types';
import { GatsbyImage } from 'gatsby-plugin-image';

const PromoBanner = () => {
  const data = useStaticQuery<OrangeBannerQuery>(graphql`
    query OrangeBanner {
      image: file(relativePath: { eq: "fast_grandma.png" }) {
        childImageSharp {
          gatsbyImageData(width: 500, placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
    }
  `);
  return (
    <div className="pt-20">
      <div
        className="py-20 lg:py-6"
        style={{
          backgroundImage:
            'linear-gradient(-268deg, #f0a621 0%, #ffdf6e 98%, #ffdf6e 100%)',
        }}
      >
        <Container>
          <div className="flex relative items-center justify-center flex-wrap">
            <div className="w-full lg:w-3/4 z-10">
              <Heading
                variant="h3"
                className="text-white text-center md:text-left italic text-6xl mb-0"
                style={{
                  color: '#ffffff',
                  fontSize: '3.75rem',
                  lineHeight: '1',
                }}
              >
                “Gli ostacoli quotidiani si
                <br className="hidden lg:block" />
                superano con l’aiuto giusto”
              </Heading>
            </div>
            <div className="pt-10 lg:pt-0 w-1/2 lg:w-1/4 md:transform lg:scale-110 lg:-translate-y-10">
              <GatsbyImage
                image={data.image.childImageSharp.gatsbyImageData}
                alt="fast grandma"
                className="w-full"
              />
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default PromoBanner;
