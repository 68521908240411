import { graphql, Link, PageProps, useStaticQuery } from 'gatsby';
import React, { ReactNode, useEffect, useState } from 'react';
import { WorkWithUgoImagesQuery } from '../../generated/graphql-types';
import SEO from '../components/core/meta/SEO';
import { Container } from '../components/core/layout/Layout';
import Heading from '../components/core/typography/Heading';
import FaqAccordion from '../components/views/FaqAccordion/FaqAccordion';
import BackgroundImage from 'gatsby-background-image';
import Button from '../components/core/buttons/Button';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { renderOptions } from '../contentful/render-options';
import useDatalayer from '../../hooks/use-datalayer';
import { useAnalytics } from '../analytics/AnalyticsProvider';
import { convertToBgImage } from 'gbimage-bridge';
import { getSrc } from 'gatsby-plugin-image';

/**
 * For some reason useEffect is not working in the Page component.
 * We wrap the button in order to pass the viral_referrer_id property to typeform url
 */
export const TypeformDOFGeneralInfoUrlButton = ({
  action,
  eventLocation,
  label = 'Unisciti a noi',
}: {
  label?: string;
  action?:
    | 'navbar_button_1'
    | 'header_button_1'
    | 'green_banner_button_1'
    | 'faq_button_1'
    | 'yellow_banner_bottom_button_1';
  eventLocation?:
    | 'm-jaf-hero_button'
    | 'm-jaf-body_button_1'
    | 'm-jaf-body_button_2'
    | 'm-jaf-body_button_3'
    | 'm-jaf-body_button_4'
    | 'm-jaf-navbar_button';
}) => {
  const [url, setUrl] = useState<string>();
  const datalayer = useDatalayer();
  const analytics = useAnalytics();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    let dofEntryUrl = process.env.GATSBY_TYPEFORM_DRIVER_DOF_ENTRY_FORM_URI;

    const viral_referrer_id = searchParams.get('ref_id');

    if (viral_referrer_id) {
      dofEntryUrl = `${dofEntryUrl}#viral_referrer_id=${viral_referrer_id}`;
    }

    setUrl(dofEntryUrl);
  }, []);

  return (
    <Link
      to={'/lavora-con-ugo-form'}
      target="_blank"
      onClick={() => {
        analytics.submitEvent('ja-started-d', { location: eventLocation });
        datalayer.triggerJobApplicationStartedDriver({ action });
      }}
    >
      <Button variant="filled" color="primary">
        {label}
      </Button>
    </Link>
  );
};

const WorkWithUgo = ({ location }: PageProps) => {
  const workWithUgoData = useStaticQuery<WorkWithUgoImagesQuery>(graphql`
    query WorkWithUgoImages {
      headerBackground: file(relativePath: { eq: "work/header_lavora.jpeg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
      services1: file(relativePath: { eq: "illustrations/booking.png" }) {
        childImageSharp {
          gatsbyImageData(height: 180, placeholder: BLURRED, layout: FIXED)
        }
      }
      services2: file(relativePath: { eq: "illustrations/spesa.png" }) {
        childImageSharp {
          gatsbyImageData(height: 180, placeholder: BLURRED, layout: FIXED)
        }
      }
      services3: file(relativePath: { eq: "illustrations/compagnia.png" }) {
        childImageSharp {
          gatsbyImageData(height: 180, placeholder: BLURRED, layout: FIXED)
        }
      }
      number1: file(relativePath: { eq: "numbers/1_1.png" }) {
        childImageSharp {
          gatsbyImageData(height: 180, placeholder: BLURRED, layout: FIXED)
        }
      }
      number2: file(relativePath: { eq: "numbers/2_1.png" }) {
        childImageSharp {
          gatsbyImageData(height: 180, placeholder: BLURRED, layout: FIXED)
        }
      }
      number3: file(relativePath: { eq: "numbers/3_1.png" }) {
        childImageSharp {
          gatsbyImageData(height: 180, placeholder: BLURRED, layout: FIXED)
        }
      }
      check: file(relativePath: { eq: "illustrations/check.png" }) {
        childImageSharp {
          gatsbyImageData(height: 180, placeholder: BLURRED, layout: FIXED)
        }
      }
      number4: file(relativePath: { eq: "numbers/4_1.png" }) {
        childImageSharp {
          gatsbyImageData(height: 180, placeholder: BLURRED, layout: FIXED)
        }
      }
      section1: file(relativePath: { eq: "lavora_sub.jpg" }) {
        childImageSharp {
          gatsbyImageData(height: 800, placeholder: BLURRED, layout: FIXED)
        }
      }
      illuback: file(relativePath: { eq: "illustrations/illu_back.png" }) {
        childImageSharp {
          gatsbyImageData(height: 400, placeholder: BLURRED, layout: FIXED)
        }
      }
      empathy: file(relativePath: { eq: "illustrations/Icon_Empatia.png" }) {
        childImageSharp {
          gatsbyImageData(height: 180, placeholder: BLURRED, layout: FIXED)
        }
      }
      gentle: file(relativePath: { eq: "illustrations/Icon_Gentilezza.png" }) {
        childImageSharp {
          gatsbyImageData(height: 180, placeholder: BLURRED, layout: FIXED)
        }
      }
      precision: file(
        relativePath: { eq: "illustrations/Icon_Precisione.png" }
      ) {
        childImageSharp {
          gatsbyImageData(height: 180, placeholder: BLURRED, layout: FIXED)
        }
      }
      bgGrain: file(relativePath: { eq: "illustrations/bg_grain.png" }) {
        childImageSharp {
          gatsbyImageData(height: 90, placeholder: BLURRED, layout: FIXED)
        }
      }
      op1: file(relativePath: { eq: "operators/op4.jpg" }) {
        childImageSharp {
          gatsbyImageData(height: 150, placeholder: BLURRED, layout: FIXED)
        }
      }
      op2: file(relativePath: { eq: "operators/op1.jpeg" }) {
        childImageSharp {
          gatsbyImageData(height: 150, placeholder: BLURRED, layout: FIXED)
        }
      }
      op3: file(relativePath: { eq: "operators/op3.jpg" }) {
        childImageSharp {
          gatsbyImageData(height: 150, placeholder: BLURRED, layout: FIXED)
        }
      }
      workFaq: allContentfulFaq(
        filter: { category: { eq: "Work With Ugo Page" } }
      ) {
        edges {
          node {
            question {
              question
            }
            answer {
              raw
            }
          }
        }
      }
    }
  `);

  const headerBackgroundBgImage = convertToBgImage(
    workWithUgoData.headerBackground.childImageSharp.gatsbyImageData
  );

  return (
    <div>
      <SEO
        title="Lavora con ugo"
        description="Il servizio UGO sta ricercando caregiver professionali.Candidati e lavora subito per affiancare persone anziane e fragili nelle attività quotidiane"
        metaImage={workWithUgoData.headerBackground.childImageSharp}
        hidePageSuffix
        url={`/lavora-con-ugo/`}
      />
      <BackgroundImage {...headerBackgroundBgImage}>
        <div
          className="bg-black bg-opacity-60 py-16 flex"
          style={{ minHeight: '460px' }}
        >
          <Container className="flex flex-col justify-center">
            <Heading
              variant="h1"
              className="text-center text-white mb-0"
              style={{ color: '#ffffff' }}
            >
              Diventa Caregiver UGO
            </Heading>
            <p className="text-white text-4xl font-bold text-center mb-10">
              I professionisti dell'accompagnamento
              <br /> e del prendersi cura
            </p>
            <div className="flex justify-center">
              <TypeformDOFGeneralInfoUrlButton
                action="header_button_1"
                eventLocation="m-jaf-hero_button"
                label="Candidati"
              />
            </div>
          </Container>
        </div>
      </BackgroundImage>
      <section className="relative">
        <div className="absolute left-0 top-0 w-full h-full grid md:grid-cols-2">
          <div className="flex-1"></div>
          <div
            className="flex-1 hidden md:flex"
            style={{
              backgroundImage: `url(${getSrc(
                workWithUgoData.section1.childImageSharp.gatsbyImageData
              )})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          ></div>
        </div>
        <Container className="z-10 relative">
          <div className="grid md:grid-cols-2">
            <div className="py-16">
              <h2 className="text-4xl font-bold">Il Caregiver UGO</h2>
              <p className="font-bold text-primary-500 text-4xl md:text-6xl my-5">
                COSA FA?
              </p>
              <p className="text-lg md:w-2/3">
                <b>Accompagna</b> e <b>affianca</b> gli anziani e i{' '}
                <b>più fragili</b> nelle attività quotidiane,{' '}
                <b>dona autonomia ed equilibrio</b> e{' '}
                <b>diventa una risorsa in più per la famiglia.</b>
                <p className="flex justify-center mt-10">
                  <TypeformDOFGeneralInfoUrlButton
                    eventLocation={'m-jaf-body_button_1'}
                  />
                </p>
              </p>
            </div>
          </div>
        </Container>
      </section>
      <section className="bg-primary-300 py-16">
        <Container>
          <p className="font-bold text-center md:w-2/3 mx-auto leading-tight mb-16 text-4xl md:text-6xl">
            Scopri se UGO è attivo nella tua città
          </p>
          <div
            style={{ backgroundColor: '#F3B23E' }}
            className="p-10 rounded-2xl font-bold mb-10 text-center md:w-3/4 mx-auto"
          >
            Milano, Monza e Brianza, Genova, Torino, Roma, Firenze, Bergamo,
            Brescia, Bologna, Padova, Siracusa, Catania, Ancona, Napoli.
          </div>
          <div className="p-10 rounded-2xl bg-white text-center md:w-3/4 mx-auto">
            <b>Se hai scoperto che UGO non è ancora attivo nella tua città</b>,
            continua a seguirci e quando arriveremo da te potrai iniziare il
            percorso di selezione.
          </div>
        </Container>
      </section>
      <section
        className="mt-0"
        style={{
          backgroundImage: `url(${getSrc(
            workWithUgoData.illuback.childImageSharp.gatsbyImageData
          )})`,
          backgroundSize: 'cover',
        }}
      >
        <Container className="py-20">
          <p className="font-bold mx-auto text-center leading-tight mb-16 text-4xl md:text-6xl">
            Quali servizi svolgerai come Caregiver?
          </p>
          <div className="grid md:grid-cols-3 gap-5">
            <div className="bg-white rounded-xl p-8 text-center">
              <p className="text-primary-500 text-2xl mb-3 font-bold">
                Accompagnamento
              </p>
              <p>
                <b>Accompagni</b> gli anziani e i più fragili{' '}
                <b>
                  durante una visita medica, una terapia, a fare una
                  passeggiata.
                </b>{' '}
                Rimani con loro <b>per tutto il tempo necessario.</b>
              </p>
            </div>
            <div className="bg-white rounded-xl p-8 text-center">
              <p className="text-primary-500 text-2xl mb-3 font-bold">
                Commissioni
              </p>
              <p>
                Svolgi e prendi in carico le <b>attività di quartiere</b> come
                andare a{' '}
                <b>
                  fare la spesa, ritirare un referto medico, andare in banca, in
                  posta.
                </b>
              </p>
            </div>
            <div className="bg-white rounded-xl p-8 text-center">
              <p className="text-primary-500 text-2xl mb-3 font-bold">
                Compagnia
              </p>
              <p>
                <b>Trascorri del tempo in compagnia</b> di chi ne ha bisogno, al{' '}
                <b>telefono</b> oppure in presenza <b>a casa o fuori</b>.
              </p>
            </div>
          </div>
        </Container>
      </section>
      <section>
        <Container className="py-16 text-center">
          <p className="font-bold mx-auto md:w-3/4 leading-tight mb-16 text-4xl md:text-6xl">
            Qualità che contano per diventare Caregiver UGO
          </p>
          <p className="mx-auto md:w-3/4">
            Per diventare un Caregiver UGO devi <b>essere automunito</b>, avere
            la <b>fedina penale pulita</b> ed essere munito di <b>smartphone</b>
            . <b>Ma soprattutto essere dotato di:</b>
          </p>
          <div className="grid md:grid-cols-3 gap-5 mt-10">
            <div className="p-5 flex flex-col justify-center items-center">
              <img
                src={
                  workWithUgoData.empathy.childImageSharp.gatsbyImageData.src
                }
              />
              <p className="text-center text-3xl text-primary-500 font-bold">
                Empatia
              </p>
            </div>
            <div className="p-5 flex flex-col justify-center items-center">
              <img
                src={workWithUgoData.gentle.childImageSharp.gatsbyImageData.src}
              />
              <p className="text-center text-3xl text-primary-500 font-bold">
                Gentilezza
              </p>
            </div>
            <div className="p-5 flex flex-col justify-center items-center">
              <img
                src={
                  workWithUgoData.precision.childImageSharp.gatsbyImageData.src
                }
              />
              <p className="text-center text-3xl text-primary-500 font-bold">
                Precisione
              </p>
            </div>
          </div>
        </Container>
      </section>
      <section
        style={{
          backgroundImage: `url(${getSrc(
            workWithUgoData.bgGrain.childImageSharp.gatsbyImageData
          )})`,
        }}
      >
        <Container className="py-16">
          <p className="font-bold mx-auto leading-tight mb-16 text-4xl md:text-6xl text-center">
            I vantaggi di collaborare con UGO
          </p>
          <div className="grid md:grid-cols-3 gap-5">
            <div className="bg-white rounded-xl p-8 text-center">
              <p className="text-primary-500 text-2xl mb-3 font-bold">
                Flessibilità e trasparenza
              </p>
              <p>
                Non hai vincoli di orari e scegli tu quando preferisci lavorare
                in base ai tuoi impegni. E ricevi i compensi in base ai servizi
                effettuati.
              </p>
            </div>
            <div className="bg-white rounded-xl p-8 text-center">
              <p className="text-primary-500 text-2xl mb-3 font-bold">
                Academy gratuita
              </p>
              <p>
                Impari nuove abilità nella ‘’UGO Academy’’, luogo di formazione
                digitale in cui migliorare le tue competenze nell’ambito
                dell’affiancamento alla persona.
              </p>
            </div>
            <div className="bg-white rounded-xl p-8 text-center">
              <p className="text-primary-500 text-2xl mb-3 font-bold">
                Collaborazione
              </p>
              <p>
                Siamo grandi nel gioco di squadra e ad ascoltare. Ti supportiamo
                e forniamo risposte alle tue domande.
              </p>
            </div>
          </div>
          <div className="flex mt-16 justify-center">
            <TypeformDOFGeneralInfoUrlButton
              eventLocation={'m-jaf-body_button_3'}
            />
          </div>
        </Container>
      </section>
      <section>
        <Container className="py-16">
          <p className="font-bold mx-auto leading-tight text-4xl md:text-6xl text-center mb-10">
            Fasi di candidatura
          </p>
          <p className="mb-20 md:w-3/4 mx-auto">
            La candidatura avviene online e richiede alcuni semplici passaggi.
            Inviamo un feedback a ogni candidato sia che l’esito sia positivo o
            negativo. Solo dopo aver ricevuto l’idoneità, potrai iniziare a
            lavorare con noi.
          </p>
          <div className="md:w-3/4 mx-auto space-y-10">
            <div className="bg-primary-300 p-5 flex items-center space-x-6 rounded-xl">
              <p className="font-bold text-7xl" style={{ color: '#31716E' }}>
                1
              </p>
              <p>
                <b>Form conoscitivo:</b> Compili il form conoscitivo cliccando
                sul tasto "Candidati".
              </p>
            </div>
            <div className="bg-primary-300 p-5 flex items-center space-x-6 rounded-xl">
              <p className="font-bold text-7xl" style={{ color: '#31716E' }}>
                2
              </p>
              <p>
                <b>Video presentazione:</b> Ti presenti rispondendo ad alcune
                semplici domande, come in un’intervista. Non temere, è
                semplicissimo e non ci vorrà molto!
              </p>
            </div>
            <div className="bg-primary-300 p-5 flex items-center space-x-6 rounded-xl">
              <p className="font-bold text-7xl" style={{ color: '#31716E' }}>
                3
              </p>
              <p>
                <b>Invio documenti:</b> I documenti personali hanno lo scopo di
                fornire informazioni precise e affidabili a garanzia del
                candidato.
              </p>
            </div>
            <div className="bg-primary-300 p-5 flex items-center space-x-6 rounded-xl">
              <p className="font-bold text-7xl" style={{ color: '#31716E' }}>
                4
              </p>
              <p>
                <b>Formazione:</b> Segui un breve corso di formazione, online e
                gratuito, sulla nostra Academy UGO per scoprire come gestire i
                servizi e i tuoi guadagni.
              </p>
            </div>
            <div className="bg-primary-300 p-5 flex items-center space-x-6 rounded-xl">
              <p className="font-bold text-7xl" style={{ color: '#31716E' }}>
                5
              </p>
              <p>
                <b>Attivazione:</b> Ricevi l’idoneità, il tuo profilo viene
                attivato e puoi iniziare a svolgere i servizi.
              </p>
            </div>
          </div>
          <div className="flex justify-center pt-16">
            <TypeformDOFGeneralInfoUrlButton
              label={'Candidati'}
              eventLocation={'m-jaf-body_button_3'}
            />
          </div>
        </Container>
      </section>
      <section className="bg-primary-300">
        <Container className="py-16">
          <p className="font-bold mx-auto leading-tight text-4xl md:text-6xl text-center mb-10">
            Tante storie diverse, una stessa mission
          </p>
          <div className="grid md:grid-cols-3 gap-10 md:gap-16">
            <div className="bg-white rounded-xl p-6 flex-col justify-between flex">
              <div>
                <div
                  className="h-16 w-16 rounded-full mb-4"
                  style={{
                    backgroundImage: `url(${getSrc(
                      workWithUgoData.op1.childImageSharp.gatsbyImageData
                    )})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }}
                ></div>
                <p className="pb-10">
                  <b>
                    Già da tempo supportavo famigliari e conoscenti nei momenti
                    di fragilità.
                  </b>{' '}
                  Ho voluto provare a mettermi a disposizione anche di altri per
                  arrotondare le mie entrate svolgendo un’attività per me
                  semplice e naturale:{' '}
                  <b>
                    con UGO ho scoperto che essere caregiver può diventare una
                    professione e un’opportunità di guadagno, proprio come
                    cercavo.
                  </b>
                </p>
              </div>

              <p className="font-bold">
                Cecilia Siddi, Milano, UGO da oltre 3 anni.
              </p>
            </div>
            <div className="bg-white rounded-xl p-6 flex-col justify-between flex">
              <div>
                <div
                  className="h-16 w-16 rounded-full mb-4"
                  style={{
                    backgroundImage: `url(${getSrc(
                      workWithUgoData.op3.childImageSharp.gatsbyImageData
                    )})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }}
                ></div>
                <p className="pb-10">
                  <b>Essere caregiver mi ha fatto conoscere di più me stessa</b>{' '}
                  e molti aspetti del mio carattere. Sin da piccola ho sempre
                  aiutato chi aveva più bisogno.{' '}
                  <b>
                    Diventare caregiver ha significato seguire la mia attitudine
                    personale
                  </b>
                  .
                </p>
              </div>

              <p className="font-bold">
                Monica Angeloni, Milano UGO da oltre 2 anni{' '}
              </p>
            </div>

            <div className="bg-white rounded-xl p-6 flex-col justify-between flex">
              <div>
                <div
                  className="h-16 w-16 rounded-full mb-4"
                  style={{
                    backgroundImage: `url(${getSrc(
                      workWithUgoData.op2.childImageSharp.gatsbyImageData
                    )})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }}
                ></div>
                <p className="pb-10">
                  <b>Mi piace assistere le persone e ascoltarle</b>. Tutti
                  apprezzano il servizio e questo ci rende anche delle persone
                  di fiducia.{' '}
                  <b>
                    Personalmente mi fa sentire amato e rispettato sia da un
                    punto di vista professionale che umano.
                  </b>
                </p>
              </div>

              <p className="font-bold">
                Mauro Cesaretti, Milano UGO da oltre 6 mesi
              </p>
            </div>
          </div>
        </Container>
      </section>
      <section className="mt-0! pt-16">
        <Container>
          <Heading variant="h2" className="mb-10">
            Domande frequenti
          </Heading>
          <FaqAccordion
            faqItems={workWithUgoData.workFaq.edges.map(({ node }) => ({
              answer: renderRichText(
                // @ts-ignore
                node.answer,
                renderOptions
              ) as ReactNode,
              question: node.question.question,
            }))}
          />
        </Container>
      </section>
      <section className="pb-16 pt-8">
        <Container>
          <div className="flex justify-center">
            <TypeformDOFGeneralInfoUrlButton
              action="faq_button_1"
              eventLocation="m-jaf-body_button_4"
            />
          </div>
        </Container>
      </section>
    </div>
  );
};

export default WorkWithUgo;
