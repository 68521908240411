import React, { PropsWithChildren } from 'react';

// Extend later if different colors needed

const List = (props: PropsWithChildren<{ className?: string }>) => {
  return (
    <ul style={{ position: 'relative' }} className={props.className} {...props}>
      {props.children}
    </ul>
  );
};

export default List;
