import BackgroundImage from 'gatsby-background-image';
import React, { ReactNode } from 'react';
import Heading from '../../core/typography/Heading';
import Button from '../../core/buttons/Button';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import List from '../../core/lists/List';
import ListItem from '../../core/lists/ListItem';
import { getAppRedirectUri, HOSTNAME } from '../../utils/getAppRedirectUri';
import { convertToBgImage } from 'gbimage-bridge';

interface ServiceItemProps {
  title: string;
  image: IGatsbyImageData;
  description: string | ReactNode;
  features: string[];
  url: string;
}

const ServiceItem = (props: ServiceItemProps) => {
  const [appLocation, setAppLocation] = React.useState<string>(
    '//app.' + HOSTNAME
  );

  React.useEffect(() => {
    setAppLocation(getAppRedirectUri());
  }, []);

  const bgImage = convertToBgImage(props.image);

  return (
    <div className="flex flex-col flex-1">
      <div className="mb-auto">
        <BackgroundImage
          {...bgImage}
          style={{ height: '220px', width: '100%' }}
          className="rounded-2xl overflow-hidden mb-5"
        />
        <Heading variant="h5">{props.title}</Heading>
        <p className="text-paragraph-condense mb-5">{props.description}</p>
        <List className="list-inside list-none mb-5">
          {props.features.map(f => (
            <ListItem className="font-bold" key={f}>
              {f}
            </ListItem>
          ))}
        </List>
      </div>
      <div>
        <a href={props.url}>
          <Button variant="filled" color="primary">
            Scopri di più
          </Button>
        </a>
      </div>
    </div>
  );
};

export default ServiceItem;
