import React, { PropsWithChildren } from 'react';

/**
 * Wrapper component for the breakpointed views
 */
const Container = (props: PropsWithChildren<{ className?: string }>) => {
  return (
    <div
      className={`container mx-auto px-5 ${props.className || ''}`}
      style={{ maxWidth: '80rem' }}
    >
      {props.children}
    </div>
  );
};

export default Container;
