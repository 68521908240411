import React, { ButtonHTMLAttributes } from 'react';
import { PropsWithChildren } from 'react';

type colorType = 'primary' | 'dark' | 'success' | 'error';

interface UgoButtonProps extends ButtonHTMLAttributes<unknown> {
  variant: 'outlined' | 'filled';
  color: colorType;
  size?: 'small' | 'medium';
  fullWidth?: boolean;
}

const getFilledButtonClasses = (color: colorType) => {
  const classes = {
    primary: `
      bg-primary-500 
      border-primary-500 
      hover:bg-dark-500 
      hover:border-dark-500 
      hover:text-white
      active:bg-primary-300 
      active:border-primary-300
      active:text-dark-500
      disabled:bg-primary-400 
      disabled:border-primary-400
      disabled:text-dark-300
    `,
  };
  return classes[color] || classes['primary'];
};

const getOutlinedButtonClasses = (color: colorType) => {
  const classes = {
    primary: `
      bg-white
      border-primary-500
      hover:border-dark-500 
      active:border-primary-300
      active:text-dark-500
      disabled:border-primary-400
      disabled:text-dark-300
    `,
  };
  return classes[color] || classes['primary'];
};

const generalButtonClasses = `
  disabled:cursor-default
  border-2
  outline-none
  rounded-full
  tracking-h5
  shadow-default
  font-bold
  text-dark-500
  font-sans
  transition duration-150 ease-out
`;

const Button = (props: PropsWithChildren<UgoButtonProps>) => {
  const {
    variant,
    color,
    size,
    fullWidth,
    children,
    className,
    ...rest
  } = props;

  const sizeClasses = {
    small: 'py-2 px-5 text-sm',
    medium: 'py-3 px-8 text-h6',
  };

  const baseClasses = [
    generalButtonClasses,
    variant === 'outlined'
      ? getOutlinedButtonClasses(color)
      : getFilledButtonClasses(color),
    sizeClasses[size || 'medium'],
    fullWidth ? 'w-full' : '',
    className,
  ].join(' ');

  return (
    <button className={baseClasses} {...rest}>
      {children}
    </button>
  );
};

export default Button;
