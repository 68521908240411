import React, { useEffect, useRef, useState } from 'react';
import Button from '../../buttons/Button';
import { navLinks } from './nav-links';
import NavbarItem from './NavbarItem';
import { Container } from '../Layout';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { getSrc } from 'gatsby-plugin-image';
import { Transition } from '@headlessui/react';
import useWindowSize from '../../../../../hooks/use-window-size';
import { getAppRedirectUri, HOSTNAME } from '../../../utils/getAppRedirectUri';
import { driverBannerHeightAtom } from '../CallDriverBanner/CallDriverBanner';
import { useAtom } from 'jotai';
import useDatalayer from '../../../../../hooks/use-datalayer';
import {
  isCalculateButtonVisibleAtom,
  isLavoraConUgoPageAtom,
  isMobileCalculateButtonVisibleAtom,
} from '../Layout/Layout';
import { TypeformDOFGeneralInfoUrlButton } from '../../../../pages/lavora-con-ugo';
import { useAnalytics } from '../../../../analytics/AnalyticsProvider';

interface NavbarProps {
  mobileMenuOpen: (mobileMenuOpen: boolean) => void;
}

const Navbar = (props: NavbarProps) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
  const [mobileMenuHeight, setMobileMenuHeight] = useState<number>(0);
  const [appLocation, setAppLocation] = useState<string>('//app.' + HOSTNAME);
  const [isLavoraConUgoPage] = useAtom(isLavoraConUgoPageAtom);
  const datalayer = useDatalayer();
  const analytics = useAnalytics();
  const [isCalculateButtonVisible] = useAtom(isCalculateButtonVisibleAtom);
  const [isMobileCalculateButtonVisible] = useAtom(
    isMobileCalculateButtonVisibleAtom
  );

  const [driverBannerHeight] = useAtom(driverBannerHeightAtom);

  const { height } = useWindowSize();

  useEffect(() => {
    setAppLocation(getAppRedirectUri());
  }, []);

  const navbarQuery = useStaticQuery(graphql`
    query Navbar {
      logo: file(relativePath: { eq: "logo/ugo_logo.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED, width: 600)
        }
      }
    }
  `);

  const twClassNames = {
    // enter: css(tw`transition ease-out duration-300 transform`),
    // enterFrom: css(tw`opacity-0 scale-95`),
    // enterTo: css(tw`opacity-100 scale-100`),
    // leave: css(tw`transition ease-in duration-75 transform`),
    // leaveFrom: css(tw`opacity-100 scale-100`),
    // leaveTo: css(tw`opacity-0 scale-95`),
  };

  const logoSrc = getSrc(navbarQuery.logo);
  const containerRef = useRef(null);

  useEffect(() => {
    setMobileMenuHeight(
      window.innerHeight -
        (containerRef.current.offsetHeight -
          containerRef.current.offsetTop +
          150)
    );
  }, [height]);

  const toggleMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
    props.mobileMenuOpen(mobileMenuOpen);
  };

  return (
    <header
      ref={containerRef}
      className={`sticky top-0 bg-white z-20 transition ease-out duration-300 shadow-sm ${
        mobileMenuOpen && 'bg-primary-300!'
      }`}
      style={{ top: driverBannerHeight + 'px' }}
    >
      <Container>
        <div className="flex justify-between items-center xl:justify-start md:space-x-14 py-6">
          <div className="flex justify-start w-1/3 sm:w-32 md:w-36">
            <Link className={`w-full block`} to="/">
              <img src={logoSrc} alt="UGO Logo" />
            </Link>
          </div>
          <div className="-mr-2 -my-2 xl:hidden flex items-center text-paragraph">
            {isLavoraConUgoPage ? (
              <TypeformDOFGeneralInfoUrlButton
                action="navbar_button_1"
                eventLocation="m-jaf-navbar_button"
              />
            ) : (
              <Link to="/#hero">
                <Button
                  id="calcola-cta-1"
                  // className={`px-4 transition-all duration-500 ease-in-out ${
                  //   isCalculateButtonVisible || isMobileCalculateButtonVisible
                  //     ? 'opacity-0 invisible'
                  //     : 'opacity-100 visible'
                  // }`}
                  style={{
                    fontSize: '0.875rem',
                    lineHeight: '1.25rem',
                    paddingLeft: '1rem',
                    paddingRight: '1rem',
                    transitionProperty: 'all',
                    transitionDuration: '500ms',
                    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
                    opacity:
                      isCalculateButtonVisible || isMobileCalculateButtonVisible
                        ? '0'
                        : '1',
                    visibility:
                      isCalculateButtonVisible || isMobileCalculateButtonVisible
                        ? 'hidden'
                        : 'visible',
                  }}
                  variant="filled"
                  color="primary"
                  onClick={() => {
                    datalayer.triggerReservationIntent({ action: 'navbar' });
                    datalayer.qf4Events.reservationIntent('Nav');
                    analytics.submitEvent<'r-intent-p'>('r-intent-p', {
                      location: 'm-qf-navbar_button',
                    });
                    if (typeof window !== 'undefined') {
                      datalayer.v3.triggerFunnelStarted(
                        window.location.pathname
                      );
                    }
                  }}
                >
                  Calcola preventivo
                </Button>
              </Link>
            )}
            {/*<a href="tel:+39 392 9967718">*/}
            {/*  <PhoneInTalk style={{ fontSize: '1.75rem' }} />*/}
            {/*</a>*/}
            <button
              onClick={toggleMenu}
              className="inline-block px-2 font-bold cursor-pointer outline-none! w-12"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                // className="h-3 w-3"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="sr-only">Open menu</span>
              {/*{mobileMenuOpen ? 'CHIUDI' : 'MENU'}*/}
            </button>
          </div>
          <nav className="hidden xl:flex space-x-8">
            {!isLavoraConUgoPage &&
              navLinks.map((link, i) => (
                <NavbarItem
                  appLocation={appLocation}
                  key={link.url + i}
                  link={link}
                />
              ))}
          </nav>
          <div className="hidden xl:flex items-center justify-end md:flex-1 lg:w-0">
            {isLavoraConUgoPage ? (
              <div className="mr-4">
                <TypeformDOFGeneralInfoUrlButton action="navbar_button_1" />
              </div>
            ) : (
              <Link to="/#hero">
                <Button
                  id="calcola-cta-2"
                  // className={`mr-4 transition-all duration-500 ease-in-out ${
                  //   isCalculateButtonVisible
                  //     ? 'opacity-0 invisible'
                  //     : 'opacity-100 visible'
                  // }`}
                  style={{
                    fontSize: '17px',
                    lineHeight: '24px',
                    paddingTop: '0.75rem',
                    paddingBottom: '0.75rem',
                    paddingLeft: '1rem',
                    paddingRight: '1rem',
                    marginRight: '1rem',
                    transitionProperty: 'all',
                    transitionDuration: '500ms',
                    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
                    opacity: isCalculateButtonVisible ? '0' : '1',
                    visibility: isCalculateButtonVisible ? 'hidden' : 'visible',
                  }}
                  variant="filled"
                  color="primary"
                  onClick={() => {
                    datalayer.triggerReservationIntent({ action: 'navbar' });
                    analytics.submitEvent<'r-intent-p'>('r-intent-p', {
                      location: 'm-qf-navbar_button',
                    });
                    datalayer.qf4Events.reservationIntent('Nav');
                    if (typeof window !== 'undefined') {
                      datalayer.v3.triggerFunnelStarted(
                        window.location.pathname
                      );
                    }
                  }}
                >
                  Calcola preventivo
                </Button>
              </Link>
            )}
            <a href={appLocation + '/login'}>
              <Button
                style={{
                  fontSize: '17px',
                  lineHeight: '24px',
                  paddingTop: '0.75rem',
                  paddingBottom: '0.75rem',
                  paddingLeft: '1rem',
                  paddingRight: '1rem',
                }}
                variant="outlined"
                color="primary"
                onClick={datalayer.triggerEnterIntent}
              >
                Accedi
              </Button>
            </a>
          </div>
        </div>
      </Container>

      {/* Mobile menu */}
      <Transition show={mobileMenuOpen} {...twClassNames}>
        <div
          className="fixed top-0 h-full bg-white overflow-y-scroll w-full bg-primary-300"
          // style={{ height: mobileMenuHeight }}
        >
          <div className="container mx-auto px-5">
            <div className="flex justify-between items-center xl:justify-start md:space-x-14 py-6 overflow-x-hidden">
              <div className="flex justify-start w-1/3 sm:w-32 md:w-36">
                <Link className="w-full block" to="/">
                  <img src={logoSrc} alt="UGO Logo" />
                </Link>
              </div>
              <div className="-mr-2 -my-2 xl:hidden">
                <button
                  onClick={toggleMenu}
                  className="inline-block px-4 font-bold cursor-pointer outline-none! w-16"
                >
                  <span className="sr-only">Close menu</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <Link
            onClick={toggleMenu}
            to="/lavora-con-ugo/"
            className="block p-4 py-5 font-bold border-dark-400 uppercase border-b"
          >
            Diventa un operatore
          </Link>
          {navLinks.map((link, i) =>
            link.redirectToApp ? (
              <Link
                onClick={() => {
                  toggleMenu();
                }}
                className={`block p-4 py-5 font-bold border-dark-400 uppercase ${
                  i < navLinks.length - 1 && 'border-b'
                }`}
                key={link.url + i}
                to={appLocation + link.url}
              >
                {link.name}
              </Link>
            ) : (
              <Link
                onClick={toggleMenu}
                className={`block p-4 py-5 font-bold border-dark-400 uppercase ${
                  i < navLinks.length - 1 && 'border-b'
                }`}
                key={link.url + i}
                to={link.url}
              >
                {link.name}

                {link.children &&
                  link.children.map(c => (
                    <Link
                      key={c.name}
                      to={c.url}
                      className="hover:bg-gray-200 rounded-md p-2 cursor-pointer flex"
                    >
                      <svg
                        height="20px"
                        className="text-primary-500 mr-2 transform rotate-180 flex-shrink-0"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M10 19l-7-7m0 0l7-7m-7 7h18"
                        />
                      </svg>
                      {c.name}
                    </Link>
                  ))}
              </Link>
            )
          )}
          <Container className="py-6">
            <Link to="/#hero">
              <Button
                // className="mb-4"
                style={{
                  marginBottom: '1rem',
                }}
                id="calcola-cta-3"
                variant="filled"
                color="primary"
                fullWidth
                onClick={() => {
                  toggleMenu();
                  datalayer.triggerReservationIntent({ action: 'navbar' });
                  datalayer.qf4Events.reservationIntent('Nav');
                  analytics.submitEvent<'r-intent-p'>('r-intent-p', {
                    location: 'm-qf-mobile_menu_button',
                  });
                  if (typeof window !== 'undefined') {
                    datalayer.v3.triggerFunnelStarted(window.location.pathname);
                  }
                }}
              >
                Calcola preventivo
              </Button>
            </Link>
            <a href={appLocation + '/login'}>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                onClick={datalayer.triggerEnterIntent}
              >
                Accedi
              </Button>
            </a>
          </Container>
        </div>
      </Transition>
    </header>
  );
};

export default Navbar;
