import React from 'react';
import Button from '../../core/buttons/Button';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { getAppRedirectUri, HOSTNAME } from '../../utils/getAppRedirectUri';
import { UgoFeaturesQuery } from 'generated/graphql-types';
import Heading from '../../../components/core/typography/Heading';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Container } from '../../core/layout/Layout';
import useDatalayer from '../../../../hooks/use-datalayer';
import { useAnalytics } from '../../../analytics/AnalyticsProvider';

const Features = () => {
  const [appLocation, setAppLocation] = React.useState<string>(
    '//app.' + HOSTNAME
  );

  const datalayer = useDatalayer();
  const analytics = useAnalytics();

  React.useEffect(() => {
    setAppLocation(getAppRedirectUri());
  }, []);

  const features = useStaticQuery<UgoFeaturesQuery>(graphql`
    query UgoFeatures {
      f1: file(relativePath: { eq: "ugo_feature_1.png" }) {
        childImageSharp {
          gatsbyImageData(height: 150, placeholder: BLURRED, layout: FIXED)
        }
      }
      f2: file(relativePath: { eq: "ugo_feature_2.png" }) {
        childImageSharp {
          gatsbyImageData(height: 150, placeholder: BLURRED, layout: FIXED)
        }
      }
      f3: file(relativePath: { eq: "ugo_feature_3.png" }) {
        childImageSharp {
          gatsbyImageData(height: 150, placeholder: BLURRED, layout: FIXED)
        }
      }
    }
  `);

  return (
    <div>
      <Container>
        <div className="flex flex-wrap md:flex-nowrap md:space-x-20 space-y-20 md:space-y-0 mb-20">
          <div className="w-full md:w-1/3">
            <Heading variant="h4" className="text-center">
              UGO di fiducia
            </Heading>
            <p className="text-paragraph text-center mb-4">
              Ti affianca sempre il tuo
              <br /> operatore UGO di fiducia
            </p>
            <div className="flex justify-center">
              <GatsbyImage
                image={features.f1.childImageSharp.gatsbyImageData}
                alt="UGO di fiducia"
              />
            </div>
          </div>
          <div className="w-full md:w-1/3">
            <Heading variant="h4" className="text-center">
              Aiuto in 24 ore
            </Heading>
            <p className="text-paragraph text-center mb-4">
              Operatori disponibili 7/7
              <br />
              con 24 di preavviso
            </p>
            <div className="flex justify-center">
              <GatsbyImage
                image={features.f2.childImageSharp.gatsbyImageData}
                alt="Aiuto in 24 ore"
              />
            </div>
          </div>
          <div className="w-full md:w-1/3">
            <Heading variant="h4" className="text-center">
              Pagamento sicuro
            </Heading>
            <p className="text-paragraph text-center mb-4">
              Pagamento online
              <br />
              diretto e tracciato.
            </p>
            <div className="flex justify-center">
              <GatsbyImage
                image={features.f3.childImageSharp.gatsbyImageData}
                alt="Pagamento sicuro"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-center mb-4">
          <Link
            to="/#hero"
            onClick={() => {
              datalayer.triggerReservationIntent({ action: 'values' });
              datalayer.qf4Events.reservationIntent('middleHome');
              analytics.submitEvent<'r-intent-p'>('r-intent-p', {
                location: 'm-qf-features_button',
              });
            }}
          >
            <Button
              variant="filled"
              color="primary"
              // className="px-20"
              style={{ paddingLeft: '5rem', paddingRight: '5rem' }}
            >
              Prenota ora
            </Button>
          </Link>
        </div>
        <div className="text-center">
          <Link
            className="text-primary-500 underline font-bold"
            to="/protocollo-sicurezza-covid19/"
          >
            {'Protocollo di sicurezza Covid19 >'}
          </Link>
        </div>
      </Container>
    </div>
  );
};

export default Features;
