import React from 'react';
import { InputHTMLAttributes } from 'react';

interface RoundedTextFieldProps extends InputHTMLAttributes<unknown> {
  fullWidth?: boolean;
  iconLeft?: JSX.Element;
  iconRight?: JSX.Element;
  error?: string;
  label?: string;
}

const RoundedTextField = ({
  error,
  iconLeft,
  iconRight,
  ...rest
}: RoundedTextFieldProps) => {
  return (
    <>
      {rest.label && (
        <label className="font-bold mb-1 inline-block px-4">{rest.label}</label>
      )}
      <div
        className={`relative h-14 w-full rounded-full overflow-hidden py-2 px-2 bg-white flex items-center shadow-lg focus-within:ring focus-within:ring-primary-500 text-left flex items-center ${
          error && 'ring ring-red-300'
        }`}
      >
        {iconLeft ? iconLeft : null}
        <input
          className={`w-full focus:outline-none! pl-2 pr-10`}
          defaultValue=""
          {...rest}
        />
        <div className="absolute right-2">{iconRight ? iconRight : null}</div>
      </div>
      {error ? (
        <p className="pt-1 text-error-500 px-5 font-bold">{error}</p>
      ) : (
        <p className="pt-1">&nbsp;</p>
      )}
    </>
  );
};

export default RoundedTextField;
