import React from 'react';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
interface CityItemProps {
  image: IGatsbyImageData;
  name: string;
}

const StyledImage = styled(BackgroundImage)`
  height: 150px;
`;

const CityItem = (props: CityItemProps) => {
  const bgImage = convertToBgImage(props.image);

  return (
    <div className="w-full shadow-card rounded-lg overflow-hidden">
      <StyledImage {...bgImage} />
      <div className="px-4 py-3 text-paragraph font-bold">{props.name}</div>
    </div>
  );
};

export default CityItem;
