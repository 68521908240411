import { graphql, StaticQuery } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React, { ReactElement } from 'react';
import { PartnersImagesQuery } from '../../../../generated/graphql-types';
import { partners } from './partners-list';

interface FormattedPartnerQueryData {
  url: string;
  image: IGatsbyImageData;
}

const PartnersQuery = ({
  render,
}: {
  render: (data: { [key: string]: FormattedPartnerQueryData }) => ReactElement;
}) => {
  return (
    <StaticQuery
      query={graphql`
        query PartnersImages {
          partner1: file(relativePath: { eq: "logo/partners/Amgen.png" }) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner2: file(
            relativePath: { eq: "logo/partners/Humanitas_IC.png" }
          ) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner3: file(relativePath: { eq: "logo/partners/IEO-1.png" }) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner4: file(
            relativePath: { eq: "logo/partners/Multimedica.png" }
          ) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner5: file(relativePath: { eq: "logo/partners/Roche.jpg" }) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner6: file(relativePath: { eq: "logo/partners/stg_2022.png" }) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner7: file(
            relativePath: { eq: "logo/partners/Humanitas_Fondazione.png" }
          ) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner8: file(
            relativePath: { eq: "logo/partners/Villagecare-2.png" }
          ) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner9: file(
            relativePath: { eq: "logo/partners/Medici_In_Famiglia_2.png" }
          ) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner10: file(relativePath: { eq: "logo/partners/ANT.jpg" }) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner11: file(relativePath: { eq: "logo/partners/AXA.jpg" }) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner12: file(relativePath: { eq: "logo/partners/TIM.jpg" }) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner13: file(
            relativePath: { eq: "logo/partners/MonteDeiPaschi.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner14: file(
            relativePath: { eq: "logo/partners/EuropAssistance.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner15: file(relativePath: { eq: "logo/partners/jointly.png" }) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner16: file(relativePath: { eq: "logo/partners/Novartis.jpg" }) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner17: file(relativePath: { eq: "logo/partners/officina.jpg" }) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner18: file(relativePath: { eq: "logo/partners/TIMWCAP.jpg" }) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner20: file(
            relativePath: { eq: "logo/partners/SanMartino_Genova.png" }
          ) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner21: file(
            relativePath: { eq: "logo/partners/Monzino_CC.png" }
          ) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner22: file(
            relativePath: { eq: "logo/partners/Lombarda_logo.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner23: file(relativePath: { eq: "logo/partners/STBariana.png" }) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner24: file(relativePath: { eq: "logo/partners/AMO.jpg" }) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner25: file(relativePath: { eq: "logo/partners/ASME.png" }) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner26: file(relativePath: { eq: "logo/partners/APM_Onlus.png" }) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner27: file(relativePath: { eq: "logo/partners/AUSILIUM.jpg" }) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner28: file(
            relativePath: { eq: "logo/partners/FIgthTheStroke.png" }
          ) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner29: file(
            relativePath: { eq: "logo/partners/SpesaSospesa.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner30: file(
            relativePath: { eq: "logo/partners/CamminoInSalute.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner31: file(relativePath: { eq: "logo/partners/Eureka.png" }) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner32: file(relativePath: { eq: "logo/partners/Flixbus.png" }) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner33: file(
            relativePath: { eq: "logo/partners/EasyWelfare.png" }
          ) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner34: file(
            relativePath: { eq: "logo/partners/IstitutoCortivo.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner35: file(
            relativePath: { eq: "logo/partners/auxologico.jpeg" }
          ) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner36: file(relativePath: { eq: "logo/partners/c_salute.jpeg" }) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner37: file(relativePath: { eq: "logo/partners/gsk.jpeg" }) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner38: file(
            relativePath: {
              eq: "logo/partners/Orizzontale_CentriDentistici.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner39: file(
            relativePath: { eq: "logo/partners/policlinico_s.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner40: file(
            relativePath: { eq: "logo/partners/sant_angela.jpeg" }
          ) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner41: file(relativePath: { eq: "logo/partners/Fiscozen.png" }) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner42: file(relativePath: { eq: "logo/partners/amplifon.png" }) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner43: file(
            relativePath: {
              eq: "logo/partners/centrofisioterapicofilippini.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner44: file(relativePath: { eq: "logo/partners/safacli.png" }) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner45: file(relativePath: { eq: "logo/partners/medihouse.png" }) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner46: file(
            relativePath: { eq: "logo/partners/skydental3d.png" }
          ) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner47: file(relativePath: { eq: "logo/partners/dmv.jpeg" }) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner48: file(relativePath: { eq: "logo/partners/asem.jpeg" }) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner49: file(relativePath: { eq: "logo/partners/uicib.jpeg" }) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner50: file(relativePath: { eq: "logo/partners/cpd.jpeg" }) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner51: file(relativePath: { eq: "logo/partners/HNP.png" }) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner52: file(relativePath: { eq: "logo/partners/loto.png" }) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner53: file(
            relativePath: { eq: "logo/partners/officine_buone.png" }
          ) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner54: file(
            relativePath: { eq: "logo/partners/europ_assistance-new.png" }
          ) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner55: file(relativePath: { eq: "logo/partners/ICC.png" }) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner56: file(
            relativePath: { eq: "logo/partners/inter_partner_assistance.png" }
          ) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner57: file(relativePath: { eq: "logo/partners/AON.png" }) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner58: file(
            relativePath: { eq: "logo/partners/jointly-new.png" }
          ) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner59: file(
            relativePath: { eq: "logo/partners/pellegrini.png" }
          ) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner60: file(relativePath: { eq: "logo/partners/randstad.png" }) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner61: file(
            relativePath: { eq: "logo/partners/day_welfare.png" }
          ) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner62: file(
            relativePath: { eq: "logo/partners/milano_aiuta.png" }
          ) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner63: file(
            relativePath: { eq: "logo/partners/comune_di_firenze.png" }
          ) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner64: file(relativePath: { eq: "logo/partners/coop.png" }) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner65: file(relativePath: { eq: "logo/partners/cdp.png" }) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner66: file(
            relativePath: { eq: "logo/partners/farmacie_comunali_firenze.png" }
          ) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner67: file(
            relativePath: { eq: "logo/partners/insieme_con_humanitas.png" }
          ) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner68: file(
            relativePath: { eq: "logo/partners/otoacustica_lombarda.png" }
          ) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
          partner69: file(
            relativePath: {
              eq: "logo/partners/ospedale_isola_tiberina_logo.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
        }
      `}
      render={(data: PartnersImagesQuery) => {
        const formattedData: { [key: string]: FormattedPartnerQueryData } = {};

        Object.keys(data).forEach(k => {
          formattedData[k] = {
            url: partners.find(p => p.image === k).url,
            image: data[k].childImageSharp.gatsbyImageData,
          };
        });

        return render(formattedData);
      }}
    />
  );
};

export default PartnersQuery;
