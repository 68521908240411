import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { pressList } from './press-list';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { SwiperOptions, Navigation, Pagination } from 'swiper';

SwiperCore.use([Navigation]);

const pressSwiperProps: SwiperOptions = {
  loop: true,
  navigation: {
    prevEl: '.nav-prev-press',
    nextEl: '.nav-next-press',
  },
  breakpoints: {
    0: {
      slidesPerView: 1,
      spaceBetween: 32,
    },
    640: {
      slidesPerView: 2,
      spaceBetween: 32,
    },
    768: {
      slidesPerView: 3,
      spaceBetween: 32,
    },
  },
};

const PressList = () => {
  const pressImages = useStaticQuery(graphql`
    query PressImages {
      press1: file(relativePath: { eq: "logo/press/24ore.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 500, placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
      press2: file(relativePath: { eq: "logo/press/corriere.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 500, placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
      press3: file(relativePath: { eq: "logo/press/LaRepubblica.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 500, placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
      press4: file(relativePath: { eq: "logo/press/Linkiesta.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 500, placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
      press5: file(relativePath: { eq: "logo/press/Wired.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 500, placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
    }
  `);

  return (
    <>
      <div className="hidden lg:flex flex-wrap justify-center">
        {pressList.map(p => (
          <div key={p.image} className="w-1/2 md:w-1/3 xl:w-1/5 px-5">
            {/*<a href={p.url}>*/}
            <GatsbyImage
              image={pressImages[p.image].childImageSharp.gatsbyImageData}
              alt={p.image}
            />
            {/*</a>*/}
          </div>
        ))}
      </div>
      <div className="lg:hidden px-5 relative">
        <Swiper {...pressSwiperProps}>
          {pressList.map(p => (
            <SwiperSlide key={p.url}>
              {/*<a href={p.url} className="px-4 block">*/}
              <GatsbyImage
                image={pressImages[p.image].childImageSharp.gatsbyImageData}
                alt={p.image}
              />
              {/*</a>*/}
            </SwiperSlide>
          ))}
        </Swiper>
        <svg
          className="nav-prev-press absolute left-5 top-1/2 transform -translate-y-1/2 z-10 text-dark-500 cursor-pointer left-0"
          height="30px"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M15 19l-7-7 7-7"
          />
        </svg>
        <svg
          className="nav-next-press absolute right-5 top-1/2 transform -translate-y-1/2 z-10 text-dark-500 cursor-pointer right-0"
          height="30px"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 5l7 7-7 7"
          />
        </svg>
      </div>
    </>
  );
};

export default PressList;
