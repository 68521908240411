import { Swiper } from 'swiper/react';
import SwiperCore, { SwiperOptions, Navigation, Pagination } from 'swiper';
import styled from 'styled-components';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import uniqueId from 'lodash/uniqueId';

SwiperCore.use([Navigation, Pagination]);

export interface SliderProps extends SwiperOptions {
  darkControls?: boolean;
  navOutside?: boolean;
  hideArrowsOnMobile?: boolean;
  hidePagination?: boolean;
  customControlsClassName?: string;
}

const StyledSwiper = styled(Swiper)<{
  $darkControls: boolean;
  $hidePagination: boolean;
}>`
  .swiper-pagination-bullet {
    background: ${({ $darkControls }) => ($darkControls ? '#a7a7a7' : '#fff')};
    opacity: 0.5;
    height: 12px;
    width: 12px;
  }
  .swiper-pagination-bullet-active {
    opacity: 1;
  }

  .swiper-padded-container {
    padding-bottom: 20px;
  }

  .swiper-pagination {
    display: ${props => (props.$hidePagination ? 'none' : 'relative')};
  }
`;

const Slider = ({
  children,
  darkControls,
  navOutside,
  hideArrowsOnMobile,
  hidePagination = false,
  customControlsClassName,
  ...props
}: PropsWithChildren<SliderProps>) => {
  const [id, setId] = useState(null);
  useEffect(() => {
    const id = uniqueId();
    console.log(id);
    setId(id);
  }, []);

  return (
    <div className="relative">
      <div className={navOutside && 'md:px-10'}>
        <StyledSwiper
          navigation={{
            // @ts-ignore
            prevEl: props.navigation?.prevEl || '.nav-prev-' + id,
            // @ts-ignore
            nextEl: props.navigation?.nextEl || '.nav-next-' + id,
          }}
          $darkControls={darkControls}
          $hidePagination={hidePagination}
          pagination={{ clickable: true }}
          className="relative"
          {...props}
        >
          {children}
        </StyledSwiper>
      </div>
      <svg
        /* @ts-ignore */
        className={`${
          props.navigation?.prevEl.split('.')[1] || 'nav-prev-' + id
        } absolute ${
          navOutside ? 'left-0' : 'left-5'
        } top-1/2 transform -translate-y-1/2 -translate-x-8 md:-translate-x-0 z-10 ${
          darkControls ? 'text-black' : 'text-white'
        } cursor-pointer ${
          hideArrowsOnMobile && 'hidden md:block'
        } ${customControlsClassName}`}
        height="40px"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M15 19l-7-7 7-7"
        />
      </svg>
      <svg
        /* @ts-ignore */
        className={`${
          props.navigation?.nextEl.split('.')[1] || 'nav-next-' + id
        } absolute ${
          navOutside ? 'right-0' : 'right-5'
        } top-1/2 transform -translate-y-1/2 z-10 translate-x-8 md:translate-x-0 ${
          darkControls ? 'text-black' : 'text-white'
        } cursor-pointer  ${
          hideArrowsOnMobile && 'hidden md:block'
        } ${customControlsClassName}`}
        height="40px"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 5l7 7-7 7"
        />
      </svg>
    </div>
  );
};
export default Slider;
