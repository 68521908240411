import React from 'react';
import { Container } from '../Layout';

const Bottombar = () => {
  return (
    <div>
      <Container className="py-4">
        <p className="text-paragraph-small">
          UGO pnp srl Sede legale: Via Panfilo Castaldi, 29 20124 Milano | P.iva
          e CF 08979490961
        </p>
      </Container>
    </div>
  );
};

export default Bottombar;
