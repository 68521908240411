/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import {
  ContentfulAsset,
  DefaultSeoQuery,
  ImageSharp,
} from '../../../../generated/graphql-types';
import { getSrc } from 'gatsby-plugin-image';

interface SEOComponentProps {
  description?: string;
  lang?: string;
  meta?: { property: string; content: any; name?: undefined }[];
  title?: string;
  hidePageSuffix?: boolean;
  url: string;
  metaImage?: Partial<ImageSharp> | Partial<ContentfulAsset>;
}

const SEO = ({
  description,
  lang = 'it',
  meta,
  metaImage,
  url,
  hidePageSuffix,
  title,
}: SEOComponentProps): ReactElement => {
  const { site, image } = useStaticQuery<DefaultSeoQuery>(
    graphql`
      query DefaultSeo {
        site {
          siteMetadata {
            siteUrl
            title
            description
            author
          }
        }
        image: file(relativePath: { eq: "logo/ugo_white.png" }) {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;
  let ogImageUrl;

  if (metaImage) {
    const metaImageSrc = getSrc(metaImage.gatsbyImageData);
    // Images from contentful dont need to be prepended with site url
    ogImageUrl = metaImageSrc.includes('ctfassets')
      ? metaImageSrc
      : site.siteMetadata.siteUrl.concat(metaImageSrc);
  } else {
    ogImageUrl = site.siteMetadata.siteUrl.concat(
      getSrc(image.childImageSharp.gatsbyImageData)
    );
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s${!hidePageSuffix ? ' - ' + defaultTitle : ''}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:url`,
          content: `${site.siteMetadata.siteUrl}${url}/`,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:domain`,
          content: site.siteMetadata.siteUrl,
        },
        {
          name: `twitter:url`,
          content: `${site.siteMetadata.siteUrl}${url}/`,
        },
      ]
        .concat([
          {
            property: 'og:image',
            content: ogImageUrl,
          },
          {
            name: 'twitter:card',
            content: 'summary_large_image',
          },
        ])
        .concat(meta || [])}
    />
  );
};

export default SEO;
