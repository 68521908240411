import React from 'react';
import ServiceItem from '../../views/ServiceItem/ServiceItem';
import { graphql, useStaticQuery } from 'gatsby';
import { OurServicesQuery } from 'generated/graphql-types';
import Heading from '../../core/typography/Heading';
import { serviceItems } from './services-data';

const OurServices = () => {
  const services = useStaticQuery<OurServicesQuery>(graphql`
    query OurServices {
      accompagnamento: file(
        relativePath: { eq: "accompagnamento_service.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 600, placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
      commissioni: file(relativePath: { eq: "commissioni_service.png" }) {
        childImageSharp {
          gatsbyImageData(width: 600, placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
      compagnia: file(relativePath: { eq: "compagnia_service.png" }) {
        childImageSharp {
          gatsbyImageData(width: 600, placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
    }
  `);

  return (
    <div>
      <Heading className="mb-10" variant="h3">
        I nostri servizi
      </Heading>
      <div className="flex flex-wrap md:flex-nowrap md:space-x-20 space-y-20 md:space-y-0">
        {serviceItems.map(service => (
          <div className="w-full md:w-1/3 flex" key={service.title}>
            <ServiceItem
              {...service}
              image={services[service.image].childImageSharp.gatsbyImageData}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurServices;
