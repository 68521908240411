import React from 'react';
import { styled } from 'twin.macro';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import Rating from '../../core/misc/Rating';

interface AboutPersonProps {
  image?: IGatsbyImageData;
  quote: string;
  name: string;
  workRole: string;
}

const AboutPersonAvatar = styled(BackgroundImage)`
  height: 80px;
  width: 80px;
`;

const AboutPerson = (props: AboutPersonProps) => {
  const bgImage = props.image ? convertToBgImage(props.image) : undefined;

  return (
    <div
      className="bg-white max-w-full p-4 pb-2 rounded-card shadow-md flex flex-col justify-between"
      {...props}
    >
      <div>
        <AboutPersonAvatar
          {...bgImage}
          Tag="div"
          className="rounded-max overflow-hidden mb-4"
        />
        <div className="text-paragraph-condense mb-4">{props.quote}</div>
      </div>
      <div>
        <p className="text-paragraph-condense font-bold">{props.name}</p>
        <p className="text-paragraph-condense font-bold">{props.workRole}</p>
        <div className="flex justify-center py-2">
          <Rating rating={5} />
        </div>
      </div>
    </div>
  );
};

export default AboutPerson;
