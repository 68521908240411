import styled from 'styled-components';
import React from 'react';
import { PropsWithChildren } from 'react';

const StyledListItem = styled.li`
  position: relative;
  padding-left: 1.25em;
  display: block;
  &::before {
    content: '•';
    left: 0;
    font-size: 2em;
    line-height: 1em;
    position: absolute;
    color: rgba(249, 178, 51, var(--tw-text-opacity));
  }
`;

const ListItem = (
  props: PropsWithChildren<{ className?: string; key?: string }>
) => {
  return (
    <StyledListItem className={`text-paragraph ${props.className}`} {...props}>
      {props.children}
    </StyledListItem>
  );
};

export default ListItem;
