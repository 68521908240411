import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import ResponsiveList from '../../views/ResponsiveList/ResponsiveList';
import PartnersQuery from '../../query-components/PartnersQuery/PartnersQuery';
import { partners } from '../../query-components/PartnersQuery/partners-list';

const PartnersList = () => {
  return (
    <PartnersQuery
      render={data => (
        <div>
          <ResponsiveList
            sliderProps={{
              hidePagination: true,
              darkControls: true,
              navOutside: true,
            }}
            classNameGridItemSetting={`md:w-1/3 xl:w-1/5 px-10 py-4`}
          >
            {PARTNER_LIST.map((partnerKey, i) => {
              return (
                <a
                  key={i}
                  href={data[partnerKey].url}
                  aria-label={partnerKey}
                  target="_blank"
                  rel="noreferrer"
                  className="px-4 block"
                >
                  <GatsbyImage
                    image={data[partnerKey].image}
                    alt={partners.find(p => p.image === partnerKey).alt}
                  />
                </a>
              );
            })}
          </ResponsiveList>
        </div>
      )}
    />
  );
};

// View CategorizedPartnersList/README.md on how do add a partner to the list
const PARTNER_LIST = [
  'partner1',
  'partner2',
  'partner16',
  'partner37',
  'partner5',
  'partner38',
  'partner7',
  'partner3',
  'partner4',
  'partner10',
  'partner6',
  'partner42',
  'partner35',
  'partner11',
  'partner39',
];

export default PartnersList;
