import React from 'react';
import { PropsWithChildren } from 'react';

interface HeadingProps {
  variant: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  className?: string;
  style?: React.CSSProperties;
  id?: string;
}

const Heading = ({
  id,
  className,
  ...props
}: PropsWithChildren<HeadingProps>) => {
  switch (props.variant) {
    case 'h1': {
      return (
        <h1
          className={`text-h1-mobile md:text-h1 tracking-h1 mb-8 break-words text-dark-500 font-bold ${className}`}
          id={id}
          {...props}
        >
          {props.children}
        </h1>
      );
    }
    case 'h2': {
      return (
        <h2
          className={`text-h2-mobile md:text-h2 tracking-h2 mb-8 text-dark-500 font-bold ${className}`}
          id={id}
          {...props}
        >
          {props.children}
        </h2>
      );
    }
    case 'h3': {
      return (
        <h3
          className={`text-h3-mobile md:text-h3 tracking-h3 mb-8 text-dark-500 font-bold ${className}`}
          id={id}
          {...props}
        >
          {props.children}
        </h3>
      );
    }
    case 'h4': {
      return (
        <h4
          className={`text-h4-mobile md:text-h4 tracking-h4 mb-4 text-dark-500 font-bold ${className}`}
          id={id}
          {...props}
        >
          {props.children}
        </h4>
      );
    }
    case 'h5': {
      return (
        <h5
          className={`text-h5-mobile md:text-h5 tracking-h5 mb-4 text-dark-500 font-bold ${className}`}
          id={id}
          {...props}
        >
          {props.children}
        </h5>
      );
    }
    case 'h6': {
      return (
        <h6
          className={`text-h6-mobile md:text-h6 tracking-h6 mb-3 text-dark-500 font-bold ${className}`}
          id={id}
          {...props}
        >
          {props.children}
        </h6>
      );
    }
  }
};

export default Heading;
