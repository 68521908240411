import { graphql, useStaticQuery } from 'gatsby';
import { AboutUsQuery } from 'generated/graphql-types';
import React from 'react';
import AboutPerson from '../../views/AboutPerson/AboutPerson';
import Heading from '../../core/typography/Heading';
import ResponsiveList from '../../../components/views/ResponsiveList/ResponsiveList';

const AboutUs = () => {
  const images = useStaticQuery<AboutUsQuery>(graphql`
    query AboutUs {
      first: file(relativePath: { eq: "04_DiconoDiNoi_1.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
      second: file(relativePath: { eq: "04_DiconoDiNoi_2.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
      third: file(relativePath: { eq: "04_DiconoDiNoi_3.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
  `);

  return (
    <div>
      <Heading className="mb-2 text-center" variant="h3">
        Ascoltiamo i nostri utenti per migliorare ogni giorno
      </Heading>
      <p className="text-paragraph text-center mb-10">
        La nostra più grande soddisfazione è vedere che{' '}
        <b>UGO diventa un punto di riferimento per i nostri</b> utenti e un
        aiuto fidato.{' '}
      </p>
      <div>
        <ResponsiveList
          sliderProps={{
            darkControls: true,
            hideArrowsOnMobile: true,
          }}
          classNameGridItemSetting={`md:w-1/3 px-10 py-4`}
        >
          <div className="relative">
            <AboutPerson
              image={images['first'].childImageSharp.gatsbyImageData}
              quote="“Ho prenotato UGO per accompagnare mio papà di 85 anni a una visita medica. L'accompagnatore A. è stato puntuale, affidabile e molto attento alle esigenze del papà. Sicuramente userò nuovamente questo servizio. Grazie.”"
              name="Marco"
              workRole="Designer & Art Director."
            />
          </div>
          <div className="relative">
            <AboutPerson
              image={images['second'].childImageSharp.gatsbyImageData}
              quote="“L’operatore J. che è venuto a portarci i farmaci è stato gentilissimo e preciso. E in quel momento così terribile, come il primo lockdown, una figura così dolce e rassicurante è stata importante. Grazie a tutti Voi di esistere.”"
              name="Luisa"
              workRole="Moglie, nonna, in pensione."
            />
          </div>
          <div className="relative">
            <AboutPerson
              image={images['third'].childImageSharp.gatsbyImageData}
              quote="“Ho contattato UGO come accompagno per un genitore anziano a una visita medica. Sono stata piacevolmente sorpresa dalla accuratezza a professionalità. Mio papà si è sentito sicuro e sereno, non ho più abbandonato questo servizio.”"
              name="Paola"
              workRole="Mamma e libera professionista."
            />
          </div>
        </ResponsiveList>
      </div>
    </div>
  );
};

export default AboutUs;
