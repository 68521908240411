import Heading from '../../core/typography/Heading';
import React from 'react';
import CollectEmail from '../CollectEmail/CollectEmail';

const SubscribeNewsletterLanding = () => {
  return (
    <div className="w-full text-center">
      <Heading variant="h4" className="text-center">
        Ricevi il pacchetto informativo!
      </Heading>
      <Heading
        variant="h6"
        className="text-center mb-8"
        style={{
          fontSize: '17px',
          lineHeight: '32px',
          letterSpacing: '-0.2px',
          fontWeight: '400',
        }}
      >
        E iscriviti alla nostra <b>newsletter</b> per ricevere una piccola guida
        dei nostri servizi e restare sempre aggiornato.
      </Heading>
      <CollectEmail offsetTerms />
    </div>
  );
};

export default SubscribeNewsletterLanding;
