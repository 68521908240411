import React, { useEffect } from 'react';
import '../theme/fonts/fonts.css';
import { Container } from '../components/core/layout/Layout';
import CityList from '../components/partials/CityList/CityList';
import AboutUs from '../components/partials/AboutUs/AboutUs';
import OurServices from '../components/partials/OurServices/OurServices';
import MainHeader from '../components/partials/MainHeader/MainHeader';
import PressList from '../components/partials/PressList/PressList';
import PartnersList from '../components/partials/PartnersList/PartnersList';
import SEO from '../components/core/meta/SEO';
import { graphql, PageProps, useStaticQuery } from 'gatsby';
import { HomePageQuery } from '../../generated/graphql-types';
import Heading from '../components/core/typography/Heading';
import BlogSlider from '../components/views/BlogSlider/BlogSlider';
import SubscribeNewsletterLanding from '../components/partials/SubscribeNewsletterLanding/SubscribeNewsletterLanding';
import UgoFeatures from '../components/partials/UgoFeatures/UgoFeatures';
import OperatorsSlider from '../components/partials/OperatorsSlider/OperatorsSlider';
import { GatsbyImage } from 'gatsby-plugin-image';
import OrangeBanner from '../components/partials/OrangeBanner/OrangeBanner';
import { FirebaseApp, getApps, initializeApp } from 'firebase/app';
import { useAuth } from '../../hooks/use-auth';
import PromoBanner2 from '../components/partials/PromoBanner/PromoBanner2';
import Reviews from '../components/partials/Reviews/Reviews';
import { useAtom, atom } from 'jotai';

const firebaseConfig = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
};

export let app: FirebaseApp;

if (!getApps().length) {
  app = initializeApp(firebaseConfig);
}

export const isBrowser = typeof window !== undefined;

export const navigatedToQuoteStep1Atom = atom(false);

const IndexPage = (props: PageProps) => {
  const data = useStaticQuery<HomePageQuery>(graphql`
    query HomePage {
      headerImage1: file(relativePath: { eq: "HomeDefDesk_compressed.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: BLURRED
            layout: FULL_WIDTH
          )
        }
      }
      grandma: file(relativePath: { eq: "grandma_drawing.png" }) {
        childImageSharp {
          gatsbyImageData(width: 500, placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
      blogs: allContentfulBlog(sort: { order: DESC, fields: date }, limit: 5) {
        nodes {
          date(locale: "it", formatString: "MMM DD. yyyy")
          title
          slug
          metaDescription
          metaTitle
          content {
            raw
            references {
              ... on ContentfulAsset {
                __typename
                contentful_id
                gatsbyImageData(
                  placeholder: BLURRED
                  layout: FULL_WIDTH
                  width: 2000
                )
              }
            }
          }
          summary
          isDummy
          image {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
      }
    }
  `);

  let auth;
  if (isBrowser) {
    auth = useAuth();
  }

  const [, setNavigatedToQuoteStep1] = useAtom(navigatedToQuoteStep1Atom);

  useEffect(() => {
    const href = props.location.href as string;
    if (href.indexOf('#hero') > -1) {
      console.log('here');
      setNavigatedToQuoteStep1(false);
      setTimeout(() => {
        setNavigatedToQuoteStep1(true);
      }, 1);
    } else {
      setNavigatedToQuoteStep1(false);
    }
  }, [props.location]);

  return (
    <>
      <SEO
        url="/"
        title="Accompagnamento per anziani e disabili"
        description="Cerchi un servizio di accompagnamento per anziani e disabili? Scopri UGO: operatori specializzati che forniranno ai tuoi cari tutto il sostegno necessario"
        metaImage={data.headerImage1.childImageSharp}
      />
      <div>
        <MainHeader image={data.headerImage1.childImageSharp.gatsbyImageData} />
        <div className="bg-primary-200 md:pt-8 pb-8 pt-0">
          <Container>
            <div className="max-w-2xl text-center mx-auto">
              <span className="font-bold inline-block bg-primary-500 px-1 md:text-2xl mb-4">
                Per chi abbiamo pensato UGO?
              </span>
              <br />
              <b>
                Per chiunque abbia una ‘fragilità’, che sia momentanea o
                permanente.
              </b>{' '}
              UGO supporta persone anziane, con disabilità motorie, persone
              ipovedenti o non vedenti, malati cronici e{' '}
              <b>chiunque gradisca un aiuto nei momenti quotidiani.</b>
              <br />
              <b>Leggi qui sotto le recensioni di chi ha già scelto UGO!</b>
            </div>
          </Container>
        </div>
      </div>
      <div className="pt-16 md:pt-20 pb-0">
        <Container>
          <Reviews />
        </Container>
      </div>
      <div className="py-16 md:py-20">
        <Container>
          <OurServices />
        </Container>
      </div>
      <div className="py-16 md:py-10">
        <Container>
          <Heading className="mb-10" variant="h3">
            Gli unici in Italia a offrirti Caregiver professionali
          </Heading>
          <p className="text-paragraph">
            <b>
              UGO risolve i bisogni degli anziani e delle loro famiglie con un
              servizio di affiancamento semplice e sicuro
            </b>
            , gestito da operatori caregiver <b>professionali</b>.
            Dall’accompagnarti e supportarti nelle visite mediche, fino a
            sbrigare per te piccole commissioni. Gestiscono in ogni fase gli
            accompagnamenti a terapie, interfacciandosi con medici e personale
            sanitario; ti aiutano a gestire la compilazione di documentazione
            medica, pratiche burocratiche e molto altro! Commissioni,
            passeggiate, compagnia.
            <br />
            <br />I nostri Caregiver seguono una <b>formazione completa</b>{' '}
            tenuta da medici e psicologi, che prosegue in costanti corsi di
            aggiornamento. La nostra serietà è stata subito premiata da{' '}
            <b>
              importanti strutture sanitarie, associazioni e case farmaceutiche
            </b>{' '}
            con cui abbiamo partnership per erogare servizi di Caregiving.
          </p>
          <PartnersList />
        </Container>
      </div>
      {/*<div className="py-16 md:py-20 bg-primary-300">*/}
      {/*  <Container>*/}
      {/*    <Features />*/}
      {/*  </Container>*/}
      {/*</div>*/}
      <div className="py-16 md:py-20 bg-primary-300">
        <Container>
          <AboutUs />
        </Container>
      </div>
      <div className="py-16">
        <Container>
          <PressList />
        </Container>
      </div>
      <div className="pb-20">
        <PromoBanner2 />
      </div>
      <div className="pb-16">
        <UgoFeatures />
      </div>
      <div className="pb-16 pt-10">
        <Container>
          <Heading variant="h3">Chi sono gli operatori UGO?</Heading>
          <OperatorsSlider />
        </Container>
      </div>
      <div className="hidden lg:block">
        <OrangeBanner />
      </div>
      <div>
        <BlogSlider blogs={data.blogs.nodes.filter(b => !b.isDummy)} />
      </div>
      <div className="bg-primary-300">
        <Container className="flex justify-center">
          <div className="py-16 pb-52 lg:py-20 relative flex justify-center">
            <div className="w-full md:w-3/4">
              <SubscribeNewsletterLanding />
            </div>
            <div className="w-40 absolute right-0 md:-right-20 lg:-right-40 bottom-0">
              <GatsbyImage
                image={data.grandma.childImageSharp.gatsbyImageData}
                alt="Grandma"
              />
            </div>
          </div>
        </Container>
      </div>
      <div className="py-16 md:py-20">
        <Container>
          <CityList />
        </Container>
      </div>
    </>
  );
};

export default IndexPage;
