import React, { useEffect, useState } from 'react';
import Heading from '../../core/typography/Heading';
import { SwiperOptions } from 'swiper';
import Slider from '../../views/Slider/Slider';
import { SwiperSlide } from 'swiper/react';
import { graphql, useStaticQuery } from 'gatsby';
import { GetReviewsQuery } from '../../../../generated/graphql-types';
import { format } from 'date-fns';
import { ReviewSourceLogo } from './ReviewSourceLogos';
import { getAppRedirectUri, HOSTNAME } from '../../utils/getAppRedirectUri';
import useDatalayer from '../../../../hooks/use-datalayer';

const swiperOptions: SwiperOptions = {
  // loop: true,
  breakpoints: {
    0: {
      slidesPerView: 1,
      spaceBetween: 32,
    },
    640: {
      slidesPerView: 2,
      spaceBetween: 32,
    },
    1360: {
      slidesPerView: 3,
      spaceBetween: 32,
    },
  },
  autoplay: {
    delay: 7500,
  },
};

const StartRating = ({ rating }: { rating: number }) => {
  return (
    <div className="flex">
      <div className="flex items-center">
        {Array.from({ length: rating }).map((_, i) => (
          <svg
            key={i}
            aria-hidden="true"
            className="w-5 h-5 text-yellow-400"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Star</title>
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
          </svg>
        ))}

        {Array.from({ length: 5 - rating }).map((_, i) => (
          <svg
            key={i}
            aria-hidden="true"
            className="w-5 h-5 text-gray-300 dark:text-gray-500"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Star</title>
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
          </svg>
        ))}
      </div>
    </div>
  );
};

const Reviews = () => {
  const [appLocation, setAppLocation] = useState<string>('//app.' + HOSTNAME);
  const datalayer = useDatalayer();

  const getReviews = useStaticQuery<GetReviewsQuery>(graphql`
    query getReviews {
      hasura {
        review(
          limit: 10
          order_by: { source_created_at: desc }
          where: {
            rating: { _gte: "4" }
            source_comment_ita: { _is_null: false }
          }
        ) {
          id
          reviewer_name
          review_source
          source_comment_ita
          rating
          source_created_at
          reviewer_photo_url
        }
      }
    }
  `);

  useEffect(() => {
    setAppLocation(getAppRedirectUri());
  }, []);

  return (
    <div>
      <Heading className="mb-14" variant="h3">
        UGO raccontato da chi ci ha dato fiducia
      </Heading>

      {/* Slider */}
      <div className="relative overflow-hidden">
        <Slider
          darkControls
          navOutside
          {...swiperOptions}
          pagination={{ clickable: true }}
        >
          {getReviews.hasura.review.map(r => (
            <SwiperSlide key={r.id} style={{ height: 'auto' }}>
              <div style={{ height: '100%' }} className="px-2 flex pb-20">
                <div className="rounded-card shadow-md overflow-hidden bg-gray-100 min-w-full">
                  <div className="p-4 h-full">
                    <div className="flex flex-row items-center mb-3">
                      <img
                        key={r.reviewer_photo_url}
                        src={r.reviewer_photo_url}
                        alt={`${r.reviewer_name}.`}
                        className="h-12 w-12 rounded-full"
                      />
                      <div className="ml-4">
                        <h4 className="text-sm font-bold text-gray-900">
                          {r.reviewer_name}
                        </h4>
                        <div className="mt-1 text-gray-400">
                          <p className="text-sm">
                            <time
                              dateTime={format(
                                new Date(r.source_created_at),
                                'dd/MM/yyyy'
                              )}
                            >
                              {format(
                                new Date(r.source_created_at),
                                'dd/MM/yyyy'
                              )}
                            </time>
                          </p>
                        </div>
                      </div>
                      <div className="flex-grow flex justify-end">
                        <div className="w-8">
                          <ReviewSourceLogo />
                        </div>
                      </div>
                    </div>
                    <StartRating rating={r.rating} />
                    <div
                      className="mt-3 max-w-none text-gray-500 overflow-auto h-32 overflow-y-scroll"
                      dangerouslySetInnerHTML={{
                        __html: r.source_comment_ita,
                      }}
                    />
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Reviews;
