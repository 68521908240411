import React from 'react';
import BackgroundImage from 'gatsby-background-image';
import { graphql, useStaticQuery } from 'gatsby';
import { operators } from './operators-data';
import { OperatorsSliderQuery } from '../../../../generated/graphql-types';
import { SwiperOptions } from 'swiper';
import { SwiperSlide } from 'swiper/react';
import Slider from '../../views/Slider/Slider';
import { convertToBgImage } from 'gbimage-bridge';

const swiperOptions: SwiperOptions = {
  loop: true,
  observer: true,
  observeParents: true,
  breakpoints: {
    0: {
      slidesPerView: 1,
      spaceBetween: 32,
    },
    640: {
      slidesPerView: 2,
      spaceBetween: 32,
    },
    1360: {
      slidesPerView: 3,
      spaceBetween: 32,
    },
  },
  autoplay: {
    delay: 7500,
  },
};

const OperatorsSlider = () => {
  const operatorsImages = useStaticQuery<OperatorsSliderQuery>(graphql`
    query OperatorsSlider {
      francesca: file(relativePath: { eq: "operators/francesca.png" }) {
        childImageSharp {
          gatsbyImageData(width: 400, placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
      fernando: file(relativePath: { eq: "operators/fernando.png" }) {
        childImageSharp {
          gatsbyImageData(width: 400, placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
      simona: file(relativePath: { eq: "operators/simona.png" }) {
        childImageSharp {
          gatsbyImageData(width: 400, placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
      sonia: file(relativePath: { eq: "operators/sonia.png" }) {
        childImageSharp {
          gatsbyImageData(width: 400, placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
      giacomo: file(relativePath: { eq: "operators/giacomo.png" }) {
        childImageSharp {
          gatsbyImageData(width: 400, placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
      mattia: file(relativePath: { eq: "operators/mattia.png" }) {
        childImageSharp {
          gatsbyImageData(width: 400, placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
      gianni: file(relativePath: { eq: "operators/gianni.png" }) {
        childImageSharp {
          gatsbyImageData(width: 400, placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
      massimiliano: file(relativePath: { eq: "operators/massimiliano.png" }) {
        childImageSharp {
          gatsbyImageData(width: 400, placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
      gabriele: file(relativePath: { eq: "operators/gabriele.png" }) {
        childImageSharp {
          gatsbyImageData(width: 400, placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
    }
  `);

  return (
    <div className="relative overflow-hidden">
      <Slider
        darkControls
        navOutside
        {...swiperOptions}
        pagination={{ clickable: true }}
      >
        {operators.map(o => {
          const img = convertToBgImage(
            operatorsImages[o.key].childImageSharp.gatsbyImageData
          );
          return (
            <SwiperSlide key={o.key} style={{ height: 'auto' }}>
              <div style={{ height: '100%' }} className="px-2 flex pb-20">
                <div className="rounded-card shadow-md overflow-hidden">
                  <BackgroundImage
                    {...img}
                    className="flex flex-col"
                    style={{ height: '200px' }}
                  />
                  <div className="p-4">
                    <p className="text-paragraph mb-8">{o.title}</p>
                    <p className="text-paragraph mb-8">{o.summary}</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Slider>
    </div>
  );
};

export default OperatorsSlider;
