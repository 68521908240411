import React, { Fragment, PropsWithChildren, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';

interface RoundedSelectProps extends PropsWithChildren<any> {
  onSelect: (value) => void;
  value: any;
  valueLabelAccessor?: string;
  iconLeft?: JSX.Element;
  label?: string;
  iconRight?: JSX.Element;
  placeholder?: string;
  error?: string;
  wrapperStyle?: React.CSSProperties;
}
export default function RoundedSelect(props: RoundedSelectProps) {
  const [show, setShow] = useState(false);

  return (
    <>
      {props.label && (
        <label className="font-bold mb-1 inline-block px-4">
          {props.label}
        </label>
      )}
      <Listbox
        value={props.value}
        onChange={props.onSelect}
        onMouseLeave={() => setShow(false)}
        style={props.wrapperStyle}
      >
        <div className="relative w-full">
          <Listbox.Button
            onMouseEnter={() => setShow(true)}
            onClick={() => setShow(true)}
            className={`relative h-14 w-full cursor-pointer rounded-full overflow-hidden py-2 px-2 bg-white flex items-center shadow-lg focus:outline-none! text-left hover:ring focus-within:ring ring-primary-500 ${
              props.error && 'ring ring-red-300'
            }`}
          >
            <div className="flex w-full items-center">
              {props.iconLeft ? props.iconLeft : null}
              <div
                className={`mx-2 whitespace-nowrap ${
                  !props.value && 'text-gray-400!'
                }`}
              >
                {props.value ? (
                  <b>
                    {props.valueLabelAccessor
                      ? props.value[props.valueLabelAccessor]
                      : props.value}
                  </b>
                ) : (
                  props.placeholder || ''
                )}
              </div>
              <div className="absolute right-2">
                {props.iconRight ? props.iconRight : null}
              </div>
            </div>
          </Listbox.Button>
          <Transition
            onClick={() => setShow(false)}
            as={Fragment}
            show={show}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-20 w-full overflow-auto rounded-2xl bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              {props.children}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
      {props.error && (
        <p className="pt-1 text-error-500 px-5 font-bold">{props.error}</p>
      )}
    </>
  );
}
